<template>
  <div class="p-6" :class="{ 'opacity-50': !risque.is_exist }">
    <div class="flex items-center">
      <component :is="risque.icon" />
      <h3 class="text-promy-blue-300 text-base font-bold pl-3">
        {{ risque.name }}
      </h3>
    </div>
    <main class="my-5">
      <div class="text-base font-normal">{{ risque.desc }}</div>
      <template v-if="risque.sub_risques.length">
        <ul class="list-inside dashed mt-1 text-base font-normal">
          <li
            v-for="(sub_risque, idx) in risque.existing_subRisques"
            :key="idx"
          >
            - {{ sub_risque }}.
          </li>
        </ul>
      </template>
    </main>
    <button
      @click="handleDetails"
      class="text-base font-semibold text-promy-green-350"
    >
      Voir le detail
    </button>
  </div>
</template>

<script>
import components from '../icons'
export default {
  components: components,
  props: {
    risque: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleDetails() {
      this.$emit('handleDetails')
    },
  },
}
</script>
