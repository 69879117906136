<template>
  <div
    class="flex flex-col justify-between bg-white rounded-lg px-5 py-4 w-full h-64"
  >
    <div>
      <p class="font-main font-bold text-base text-promy-green-350">
        {{ RisqueStatus }}
      </p>
      <p class="font-main text-promy-dark-purple font-bold text-6xl mt-7">
        {{ RisqueValue }}
      </p>
    </div>
    <button
      @click="HandleDetails"
      class="bg-promy-green-350 w-fit px-5 py-2 rounded-md text-white font-semibold text-base mt-12 mb-3"
    >
      Voir le detail
    </button>
  </div>
</template>

<script>
export default {
  props: {
    RisqueStatus: {
      type: String,
      required: true,
    },
    RisqueValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    HandleDetails() {
      this.$emit('HandleDetails')
    },
  },
}
</script>
