<template>
  <div>
    <div
      class="
        grid grid-cols-4
        md:grid-cols-2 md:gap-y-5
        lg:grid-cols-2 lg:gap-y-5
        sm:flex sm:flex-col
        gap-x-5
      "
    >
      <div class="col-span-2 sm:grid-cols-1 sm:mt-7">
        <ExpositionScore
          :ExpositionStatus="getRisquesStatus"
          chartId="Semi-circle-chart-s"
          :dataset="[getDegRisques(), 180 - getDegRisques()]"
        />
      </div>
      <RisqueNumber
        RisqueStatus="Risques majeurs"
        :RisqueValue="nbr_risques_exist"
        @HandleDetails="HandleDetails"
      />
      <RisqueNumber
        v-if="risques.catnat"
        RisqueStatus="Catastrophes naturelles"
        :RisqueValue="risques.catnat.length"
        @HandleDetails="scrollToAccordion('catasrophesNaturelles')"
      />
    </div>
    <h2
      class="
        font-bold
        text-base
        sm:text-base
        md:text-xl
        sm:ml-2
        text-promy-dark-purple
        my-5
        sm:my-8
      "
    >
      {{ nbr_risques_exist }} risques présents sur 14 risque évalués
    </h2>
    <div class="grid grid-cols-2 md:grid-cols-1 md:gap-y-6 bg-white">
      <PresentRisque
        v-for="(risque, index) in list_risques"
        :key="index"
        :risque="risque"
        @handleDetails="scrollToAccordion(`accordion-item-${risque.key}`)"
      />
    </div>
  </div>
</template>

<script>
import ExpositionScore from './components/ExpositionScore.vue'
import RisqueNumber from './components/RisqueNumber.vue'
import PresentRisque from './components/PresentRisque.vue'

import _ from 'lodash'

export default {
  props: {
    risques: {
      type: Object,
      required: true,
    },
  },
  components: {
    ExpositionScore,
    RisqueNumber,
    PresentRisque,
  },
  data() {
    return {
      nbr_risques_exist: 0,

      list_risques: [
        {
          key: 'SEISM',
          name: 'Séisme',
          numRisque: 158,
          desc: 'La sismicité de cette commune est très faible.',
          icon: 'SeismIcon',
          is_exist: false,
          sub_risques: [],
          status: ['très faible', 'faible', 'modérée', 'moyenne', 'forte'],
        },
        {
          key: 'CAN',
          name: 'Transport de marchandises dangereuses',
          numRisque: 226,
          desc: "Cette commune n'est pas située dans une zone de transports de marchandises",
          icon: 'DangerousTransportIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', // check
          name: 'Avalanche',
          numRisque: 159,
          desc: "Cette commune n'est pas située dans une zone sensible aux avalanches.",
          icon: 'avalancheIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', // check
          name: 'Risques miniers',
          desc: "Cette commune n'est pas située dans une zone sensible à l’exposition aux émissions de gaz de mine.",
          icon: 'GasMaskIcon',
          is_exist: false,
          groups: [
            'Mouvements de terrains miniers',
            'Inondations de terrain minier',
          ],
          sub_risques: [
            {
              name: 'Mouvements de terrains miniers',
              numRisque: 134,
            },
            {
              name: 'Mouvements de terrains miniers - Effondrements généralisés',
              numRisque: 252,
            },
            {
              name: 'Mouvements de terrains miniers - Effondrements localisés',
            },
            {
              name: 'Mouvements de terrains miniers - Affaissements progressifs',
            },
            {
              name: 'Mouvements de terrains miniers - Tassements',
              numRisque: 157,
            },
            {
              name: 'Mouvements de terrains miniers - Glissements ou mouvements de pente',
              numRisque: 288,
            },
            {
              name: 'Mouvements de terrains miniers - Coulées',
            },
            {
              name: 'Mouvements de terrains miniers - Écroulements rocheux',
            },
            {
              name: 'Inondations de terrain minier',
            },
            {
              name: 'Inondations de terrain minier - Pollution des eaux souterraines et de surface',
            },
            {
              name: 'Inondations de terrain minier - Pollution des sédiments et des sols',
            },
            {
              name: 'Emissions en surface de gaz de mine',
            },
          ],
        },
        {
          key: '', //check
          name: 'Engins de guerre',
          numRisque: 231,
          desc: "Cette commune n'est pas située dans une zone de risques de découvertes d’engins de guerre.",
          icon: 'enginGuerreIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', //check
          name: 'Feu de forêt',
          plural: 'feux de forêt',
          numRisque: 166,
          desc: "Cette commune n'est pas située dans une zone sensible aux feux de forêt.",
          icon: 'feuForetIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: 'INN',
          name: 'Inondation',
          plural: 'inondations',
          numRisque: 140,
          //  Elle est également soumise à un PPRN (Plan de Prévention des Risques Naturels Prévisibles) ou un PSS (Plan de Surfaces Submersibles).
          desc: "Cette commune n'est pas située dans une zone sensible aux inondations.",
          icon: 'InnIcon',
          is_exist: false,
          sub_risques: [
            {
              name: "Inondation - Par une crue (débordement de cours d'eau)",
            },
            {
              name: 'Inondation - Par une crue à débordement lent de cours d’eau',
            },
            {
              name: 'Inondation - Par une crue torrentielle ou à montée rapide de cours d’eau',
              numRisque: 180,
            },
            {
              name: 'Inondation - Par ruissellement et coulée de boue',
            },
            {
              name: 'Inondation - Par lave torrentielle (torrent et talweg)',
            },
            {
              name: 'Inondation - Par remontées de nappes naturelles',
              numRisque: 285,
            },
            {
              name: 'Inondation - Par submersion marine',
              numRisque: 286,
            },
          ],
        },
        {
          key: 'MVMT',
          name: 'Mouvement de terrain',
          numRisque: 134,
          desc: "Cette commune n'est pas située dans une zone sensible aux mouvements de terrain.",
          icon: 'MvmtIcon',
          is_exist: false,
          sub_risques: [
            {
              name: 'Mouvement de terrain - Affaissements et effondrements liés aux cavités souterraines (hors mines)',
              numRisque: 135,
            },
            {
              name: 'Mouvement de terrain - Effondrement',
            },
            {
              name: 'Mouvement de terrain - Eboulement, chutes de pierres et de blocs',
              numRisque: 143,
            },
            {
              name: 'Mouvement de terrain - Glissement de terrain',
              numRisque: 147,
            },
            {
              name: 'Mouvement de terrain - Avancée dunaire',
              numRisque: 152,
            },
            {
              name: 'Mouvement de terrain - Recul du trait de côte et de falaises',
              numRisque: 153,
            },
            {
              name: 'Mouvement de terrain - Tassements différentiels',
              numRisque: 157,
            },
          ],
        },
        {
          key: 'NUC',
          name: 'Nucléaire',
          numRisque: 230,
          desc: "Cette commune n'est pas située dans une zone sensible à l’exposition au nucléaire.",
          icon: 'NucIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', // check
          name: 'Phénomène atmosphérique',
          plural: 'phénomènes atmosphériques',
          numRisque: 167,
          desc: "Cette commune n'est pas située dans une zone sensible à des phénomènes météorologiques (vents violents, grêles, neige, foudre, etc).",
          icon: 'PhenomenesMeteorologiquesIcon',
          is_exist: false,
          sub_risques: [
            {
              name: 'Phénomènes météorologiques - Cyclone/ouragan (vent)',
              numRisque: 168,
            },
            {
              name: 'Phénomènes météorologiques - Tempête et grains (vent)',
              numRisque: 169,
            },
            {
              name: 'Phénomènes météorologiques - Foudre',
            },
            {
              name: 'Phénomènes météorologiques - Grêle',
            },
            {
              name: 'Phénomènes météorologiques - Neige et Pluies verglaçantes',
            },
          ],
        },
        {
          key: 'RADON',
          name: 'Radon',
          numRisque: 229,
          desc: "Cette commune n'est pas située dans une zone sensible à l’exposition au Radon.",
          icon: 'RadonIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: 'INST',
          name: 'Risque industriel',
          plural: 'risques industriels',
          numRisque: 215,
          desc: "Cette commune n'est pas située dans une zone sensible aux risques industriels (Effet toxique, thermique, etc).",
          icon: 'InstIcon',
          is_exist: false,
          sub_risques: [
            {
              name: 'Risque industriel - Effet thermique',
              numRisque: 236,
            },
            {
              name: 'Risque industriel - Effet de surpression',
              numRisque: 237,
            },
            {
              name: 'Risque industriel - Effet toxique',
              numRisque: 238,
            },
            {
              name: 'Risque industriel - Effet de projection',
              numRisque: 295,
            },
          ],
        },
        {
          key: '', //check
          name: 'Rupture de barrage',
          plural: 'ruptures de barrage',
          desc: "Cette commune n'est pas située dans une zone sensible à la rupture de barrage.",
          icon: 'RuptureBarrageIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', //check
          name: 'Eruption volcanique',
          numRisque: 160,
          desc: "Cette commune n'est pas située dans une zone volcanique.",
          icon: 'feuForetIcon',
          is_exist: false,
          sub_risques: [],
        },
      ],
    }
  },
  created() {
    let vm = this
    this.list_risques.forEach((item) => {
      item.is_exist = vm.check_risque(item)
    })
    this.checkDescription()
    if (this.risques.catnat) {
      this.catastrophes_naturelles = _.groupBy(
        this.risques.catnat,
        'libRisqueJO',
      )
    }
  },
  mounted() {
    this.list_risques.sort(function (x, y) {
      return x.is_exist === y.is_exist ? 0 : x.is_exist ? -1 : 1
    })
    this.nbr_risques_exist = this.list_risques.filter(
      (item) => item.is_exist === true,
    ).length
  },
  methods: {
    HandleDetails() {
      document
        .getElementById('section-detais-risques')
        .scrollIntoView({ behavior: 'smooth' })
    },
    check_risque(risque) {
      return this.risques.risqueDetail.some((item) => {
        return item.libRisqueLong === risque.name
          ? item.libRisqueLong === risque.name
          : risque.sub_risques.some(
              (sub_risque) => item.libRisqueLong === sub_risque.name,
            )
      })
    },
    checkDescription() {
      this.list_risques.forEach((risque) => {
        risque.existing_subRisques = []
        if (risque.is_exist) {
          let risque_api = this.findByNameRisque(risque.name)
          let is_exist_risque_api =
            risque_api !== undefined && risque_api.hasOwnProperty('sismicite')
              ? risque_api.sismicite
              : null
          if (is_exist_risque_api) {
            let sismicite = risque_api.sismicite
            if (sismicite) {
              risque.desc = `Le ${risque.name.toLowerCase()} de cette commune est ${
                risque.status[sismicite - 1]
              } (${sismicite}/5)`
            }
          } else if (risque.sub_risques.length) {
            risque.existing_subRisques = risque.sub_risques
              .filter((item) => {
                return this.findByNameRisque(item.name)
              })
              .map((item) => item.name)
              .map((item) => {
                if (risque.groups) {
                  risque.groups.forEach((group) => {
                    item = `${item.replaceAll(group + ' - ', '')}`
                  })
                } else {
                  item = item.replaceAll(risque.name + ' - ', '')
                }
                return item
              })

            risque.desc = `Cette commune est située dans une zone sensible aux ${risque.name.toLowerCase()}.`

            if (risque.plural) {
              if (risque.name === 'Phénomène atmosphérique') {
                risque.desc = `Cette commune est située dans une zone sensible aux ${risque.plural} suivants.`
              } else {
                risque.desc = `Cette commune est située dans une zone sensible aux ${risque.plural}.`
              }
            }

            if (risque.name === 'Mouvement de terrain') {
              risque.desc = `Cette commune est située dans une zone sensible aux ${risque.name.toLowerCase()}.`
            }
          } else {
            risque.desc = `Cette commune est située dans une zone sensible au ${risque.name.toLowerCase()}.`
          }
        }
        if (
          this.risques.radon &&
          this.risques.radon.length &&
          risque.numRisque == 229
        ) {
          risque.desc = `Cette commune est située dans une zone sensible au ${risque.name.toLowerCase()} de catégorie ${
            this.risques.radon[0].classePotentiel
          }.`
          if (['2', '3'].includes(this.risques.radon[0].classePotentiel)) {
            risque.is_exist = true
          }
        }
      })
    },
    findByNameRisque(name) {
      return this.risques.risqueDetail.find((item) => {
        if (item.libRisqueLong === name) {
          return item
        }
      })
    },
    //pie
    getDegRisques() {
      if (this.nbr_risques_exist == 0 || this.nbr_risques_exist == 1) {
        return Math.round(
          (this.nbr_risques_exist * 180) / this.list_risques.length,
        )
      } else if (this.nbr_risques_exist == 2 || this.nbr_risques_exist == 3) {
        return Math.round(
          (this.nbr_risques_exist * 180) / this.list_risques.length / 3 + 90,
        )
      } else if (this.nbr_risques_exist > 3) {
        return Math.round(
          (this.nbr_risques_exist * 180) / this.list_risques.length / 3 + 90,
        )
      }
    },
    scrollToAccordion(targetID) {
      let scroll_el = document.querySelector(`#${targetID}`)
      if (scroll_el) {
        setTimeout(function () {
          scroll_el.scrollIntoView()
        }, 500)
        if (
          !document.querySelector(
            `#${targetID} .accordion__trigger.accordion__trigger_active`,
          )
        )
          document.querySelector(`#${targetID} .accordion__trigger`).click()
      }
    },
  },
  computed: {
    getRisquesStatus() {
      if (this.nbr_risques_exist === 0 || this.nbr_risques_exist === 1) {
        return 'faible'
      } else if (this.nbr_risques_exist > 1 && this.nbr_risques_exist < 4) {
        return 'moyen'
      } else {
        return 'fort'
      }
    },
  },
}
</script>
