<template>
  <div
    class="flex sm:flex-col justify-between bg-white rounded-lg px-5 py-4 box w-full h-64 md:h-60 sm:h-80 pb-11"
  >
    <div class="relative flex flex-1 flex-col">
      <p class="font-bold text-base text-promy-green-350 mb-5">
        Exposition aux risque
      </p>
      <span class="font-normal text-base font-main"
        >La commune se situe dans <br />
        une zone à risque {{ ExpositionStatus }}.</span
      >
    </div>

    <div class="relative flex font-normal text-base sm:text-sm items-center">
      <div class="flex self-end pb-5"><span>Faible</span></div>
      <div class="flex flex-col">
        <div class="flex justify-center sm:relative sm:top-5">
          <span>Moyen</span>
        </div>

        <div class="px-4 pt-2 sm:w-52 h-36 sm-ws:h-28 w-80 sm-ws:w-64">
          <canvas :id="chartId" />
        </div>
      </div>
      <div class="flex self-end">
        <span class="pb-5">Fort</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ExpositionStatus: {
      type: String,
      required: true,
    },
    chartId: {
      type: String,
    },
    dataset: {
      type: Array,
    },
  },

  watch: {
    dataset() {
      this.renderPie()
    },
  },
  methods: {
    renderPie() {
      var doghnut1 = document.getElementById(this.chartId)
      var ctx = doghnut1.getContext('2d')
      var gradient = ctx.createLinearGradient(0, 0, 300, 0)
      gradient.addColorStop(0, '#21EB1D')
      gradient.addColorStop(0.5, '#EBB11D')
      gradient.addColorStop(1, '#EB1D1D')
      new this.$chart(doghnut1, {
        type: 'doughnut',
        data: {
          labels: [70, 30],
          datasets: [
            {
              label: this.title,
              data: this.dataset,
              backgroundColor: ['#1D63EB', '#EAEAEA'],
              borderWidth: 0,
              width: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          cutout: '70%',
          rotation: -90,
          circumference: 180,
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
          animation: {
            animateRotate: true,
            animateScale: true,
          },
        },
      })
    },
  },
}
</script>
